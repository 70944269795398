<template>
  <v-dialog :persistent="true" v-model="active" open-on-hover width="auto" max-width="700px">
    <v-card class="pa-0 rounded-lg">
      <v-card-title class="solid-heading rounded-t-lg my-0 py-6 px-2 px-sm-10">
        <div class="card-title text-h3 font-weight-semibold white--text mt-2 text-left">
          {{ drugData.name }}<v-icon :color="drugData.color" large class="mr-2 mb-2">mdi-circle</v-icon>
        </div>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-row  style="background: #381273" class="px-12 pt-4 white--text">
          <h3 class="text-h3 mb-0">{{ $t("Recomendacion") }}</h3>
        </v-row>
        <v-row  style="background: #381273" class="px-12 pb-4 white--text">
          <div class="">{{ drugData.recomendation }}</div>
        </v-row>

        <!-- FARMACOGENETICA -->
        <v-row class="mt-4 px-10">
          <h4 class="text-h3 primary--text mb-2">{{ $t("farmacogenetica") }}</h4>
        </v-row>
        <v-row class="px-16">
          <h4 class="mr-2 primary--text ">{{ $t("Substrato de") }}</h4>
          <div v-for="g in drugData.drugSubstratOfGenesResults" :key="g.gene" class="" >
            <gene-results-detailed :geneResults="g" />
          </div>
        </v-row>
        <v-row class="px-16">
          <h4 class="mr-2 primary--text ">{{ $t("Inhibe") }}</h4>
          <div class="d-flex flex-wrap">
            <a v-for="el in drugData.drugInhibits" :key="el.key"><ActiveChip entity="gene" :elementId="el.id" class="mx-2" /></a>
          </div>
        </v-row>
        <v-row class="px-16">
          <h4 class="mr-2 primary--text">{{ $t("Induce") }}</h4>
          <div class="d-flex flex-wrap">
            <a class="darkgray--text" v-for="el in drugData.drugInduces" :key="el.gen" > <ActiveChip entity="gene" :elementId="el.gen" class="mx-2" /></a>
          </div>
        </v-row>
          
        <v-divider></v-divider>
        
        <!-- NOMBRES COMERCIALES -->
        <v-row class="px-10">
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3 class="text-h3 primary--text ">
                  {{ $t("nombres comerciales") }}
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="px-6">
                  {{ drugData.brandNames }}
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-row>

        <v-divider></v-divider>
        
        <!-- ALTERNATIVAS POR CATEGORÍAS -->
        <v-row class="px-10">
          <h3 class="text-h3 primary--text">
            {{ $t("alternativas por categoria") }}
          </h3>
          <v-row class="px-6 my-2">
            <v-col cols="4" class="pa-0 pr-1">
              <h4 class="primary--text ">
                {{ $t("subgrupo farmacologico") }}
              </h4>
            </v-col>
            <v-col class="pa-0 border-left pl-2">
              <div v-for="cat in drugData.atc3Alternatives" :key="cat.key" >
                {{ cat.name }}
                <div class="d-flex flex-wrap">
                  <a class=" darkgray--text" v-for="alternative in cat.alternatives" :key="alternative.id" >
                    <ActiveChip entity="drug" :elementId="alternative.id" />
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row class="px-6 my-2">
            <v-col cols="4" class="pa-0 pr-1">
              <h4 class="primary--text ">
                {{ $t("subgrupo quimico") }}
              </h4>
            </v-col>
            <v-col class="pa-0 border-left pl-2">
              <div v-for="cat in drugData.atc4Alternatives" :key="cat.key" >
                {{ cat.name }}
                <div class="d-flex flex-wrap">
                  <a
                    class="darkgray--text"
                    v-for="alternative in cat.alternatives"
                    :key="alternative.id"
                  >
                    <ActiveChip entity="drug" :elementId="alternative.id" />
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-row>

        <v-divider></v-divider>

        <!-- USO TERAPEUTICO -->
        <v-row class="px-10">
          <div v-if="drugData.hasTherapeuticUse">
            <h3 class="text-h3 primary--text ">
              {{ $t("therapeutic_use") }}
            </h3>
            <p>
              {{ drugData.therapeuticUse }}
            </p>
          </div>

          <div v-if="drugData.hasIndications" class="">
            <h3 class="text-h4 primary--text ">
              {{ $t("indicaciones") }}
            </h3>
            <p>
              {{ drugData.drugIndications }}
            </p>
          </div>

          <div v-if="drugData.hasOffLabelUse" class="">
            <h3 class="text-h4 primary--text">
              {{ $t("uso no etiquetado") }}
            </h3>
            <p>
              {{ drugData.offLabelUse }}
            </p>
          </div>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-around py-6 mt-8" fluid>
        <v-btn color="darkprimary" rounded class="" @click="emitClose">
          {{ $t("Cerrar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ActiveChip from "@/components/mylogy/ActiveChip";
import GeneResultsDetailed from "@/components/mylogy/GeneResultsDetailed.vue";

export default {
  name: "DrugResultDialog",
  components: { ActiveChip, GeneResultsDetailed },
  props: ["active", "drugData"],
  methods: {
      emitClose() {
          this.$emit('close')
      }
  }
};
</script>

<style lang="scss" scoped>
.card-title {
  line-height: 1em !important;
}
.border-left {
  border-left: solid #381273;
}
.v-card .v-card__actions {
  background: #ffffff00;
  box-shadow: 0 5px 30px -20px inset #310ab1;
}
</style>