<script>
import appbase from "@/services/appbase.js";
import { mapGetters } from "vuex";

export default {
  props: {
    drug: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      colors: ["#b0e0e6", "grey",  "green", "yellow", "orange", "red"],
      
      alternatives: [],
      modelFamilies: {
        mixed: ["phase-i", "phase-ii", "transporter", "receptor", "pathogenic"],
        splitted: ["phase-i", "phase-ii", "transporter"]
      }
    };
  },
  created() {
    this.loadAlternatives();
  },
  computed: {
    ...mapGetters("pgx", [
      "getDrugResult",
      "getGeneResult",
      "getNotNeutralGenes",
      "getModel"
    ]),
    name() {
      return (
        this.drug[this.$i18n.locale] ||
        this.drug["_id"].replaceAll("-", " ").toUpperCase()
      );
    },
    color() {
      return this.colors[this.getDrugResult(this.drug)[0].code];
    },
    recomendation() {
      return this.$t(this.getDrugResult(this.drug)[0].recomendation);
    },
    drugSubstratOfGenesResults() {
      return this.getDrugSubstratOfGenesResults();
    },
    drugInhibits() {
      return this.drug["inhibits"].map(e => {
        return { key: e, id: e.split("|")[0] };
      });
    },
    drugInduces() {
      return this.drug["induces"];
    },
    brandNames() {
      return (this.drug.names && this.drug.names.join(", ")) || "";
    },
    hasTherapeuticUse() {
        return this.drug[`has_therapeutic_use`];
    },
    therapeuticUse() {
      return this.drug[`therapeutic_use_${this.$i18n.locale.toUpperCase()}`];
    },
    hasIndications() {
      return (
        this.drug[`ind_${this.$i18n.locale.toUpperCase()}`] &&
        this.drug[`ind_${this.$i18n.locale.toUpperCase()}`].length > 0
      );
    },
    drugIndications() {
      return (
        (this.drug[`ind_${this.$i18n.locale.toUpperCase()}`] &&
          this.drug[`ind_${this.$i18n.locale.toUpperCase()}`].join(", ")) ||
        ""
      );
    },
    hasOffLabelUse() {
      return (
        this.drug[`off_${this.$i18n.locale.toUpperCase()}`] &&
        this.drug[`off_${this.$i18n.locale.toUpperCase()}`].length > 0
      );
    },
    offLabelUse() {
      return (
        (this.drug[`off_${this.$i18n.locale.toUpperCase()}`] &&
          this.drug[`off_${this.$i18n.locale.toUpperCase()}`].join(", ")) ||
        ""
      );
    },
    atc3Alternatives() {
      let self = this;
      return this.drug["categories"].map((e, index) => {
        return {
          name: self.drug[`atc_3_${self.$i18n.locale.toUpperCase()}`][index],
          key: index,
          alternatives: self.getFilteredAltervatives3(
            self.drug[`atc_3_ES`][index]
          )
        };
      });
    },
    atc4Alternatives() {
      let self = this;
      return this.drug["categories"].map((e, index) => {
        return {
          name: self.drug[`atc_4_${self.$i18n.locale.toUpperCase()}`][index],
          key: index,
          alternatives: self.getFilteredAltervatives4(
            self.drug[`atc_4_ES`][index]
          )
        };
      });
    },
    drugId() {
      return this.drug._id;
    },
    drugData() {
      return {
        drugId: this.drugId,
        color: this.color,
        name: this.name,
        brandNames: this.brandNames,
        recomendation: this.recomendation,
        drugSubstratOfGenesResults: this.drugSubstratOfGenesResults,
        drugInhibits: this.drugInhibits,
        drugInduces: this.drugInduces,
        atc3Alternatives: this.atc3Alternatives,
        atc4Alternatives: this.atc4Alternatives,
        hasTherapeuticUse: this.hasTherapeuticUse,
        therapeuticUse: this.therapeuticUse,
        hasIndications: this.hasIndications,
        drugIndications: this.drugIndications,
        hasOffLabelUse: this.hasOffLabelUse,
        offLabelUse: this.offLabelUse
      };
    }
  },
  methods: {
    getDrugSubstratOfGenesResults() {
      let geneSubstratResults = this.drug["substrate_of"]
        .map(gene => {
          return this.getGeneResult(gene.split("|")[0]);
        })
        .filter(gene => {
          return (
            gene.gene != false &&
            this.modelFamilies[this.getModel.val].includes(gene.class)
          );
        });
      return [...new Set(geneSubstratResults)].sort((a, b) =>
        a.code < b.code ? 1 : b.code < a.code ? -1 : 0
      );
    },
    getFilteredAltervatives3(atc3ES) {
      return this.alternatives.filter(a => a.cat3 == atc3ES);
    },
    getFilteredAltervatives4(atc4ES) {
      return this.alternatives.filter(a => a.cat4 == atc4ES);
    },
    loadAlternatives() {
      let self = this;
      appbase.drugs
        .search({
          body: {
            query: {
              bool: {
                must: [
                  {
                    bool: {
                      should: self.drug["atc_3_ES"].map(e => {
                        return { term: { "atc_3_ES.keyword": e } };
                      }),
                      must_not: [
                        {
                          terms: {
                            substrate_of: self.getNotNeutralGenes
                          }
                        }
                      ]
                    }
                  },
                  {
                    term: {
                      "has_pgx_60.keyword": "y"
                    }
                  }
                ]
              }
            }
          }
        })
        .then(function(response) {
          self.alternatives = response.hits.hits.map(h => {
            return {
              id: h._id,
              cat4: h._source.atc_4_ES,
              cat3: h._source.atc_3_ES
            };
          });
          console.log(self.alternatives )
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
