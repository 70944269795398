<template>
  <v-dialog :persistent="true" v-model="active" width="auto" max-width="700px" class="overflow-overlay">
    <v-card id="gene-result-dialog" class="pa-0 rounded-lg">
      <v-card-title class="solid-heading rounded-t-lg my-0 py-6 px-2 px-sm-10">
        <div class="card-title text-h3 font-weight-semibold white--text mt-2">
          {{ geneData.nameResult }} <v-icon :color="geneData.color" large class="lightbulb">mdi-circle</v-icon>
        </div>
        <v-card-subtitle class="white--text text-h4 py-0 font-weight-light">
          {{ geneData.name }}
        </v-card-subtitle>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row  style="background: #381273" class="px-16 py-8">
          <v-row>
            <div class="white--text font-weight-light text-h3"> {{$t("db_identifier")}} </div>
          </v-row>
          <v-row justify="space-between">
            <div class="my-1 white--text"><strong class="font-weight-semibold accent--text">PHARMGKB:</strong> {{ geneData.genePharmgkb }}</div>
            <div class="my-1 white--text"><strong class="font-weight-semibold accent--text">OMIM:</strong> {{ geneData.geneOMIM }}</div>
            <div class="my-1 white--text"><strong class="font-weight-semibold accent--text">LOCUS:</strong> {{ geneData.geneLocus }}</div>
            <div class="my-1 white--text"><strong class="font-weight-semibold accent--text">dbSNP ID:</strong> {{ geneData.geneSNPs.join(", ") }}</div>
            <div class="my-1 white--text"><strong class="font-weight-semibold accent--text">ALIAS:</strong> {{ geneData.geneAliases.join(", ") }}</div>
          </v-row>
        </v-row>

        <v-row class="px-10 pt-6">
          <div v-for="snp in geneData.snpsInvolved" :key="snp.snp" class="d-flex justify-space-between" >
            <span style>{{ snp.snp }}</span>
            <span style>{{ snp.genotype }}<v-icon :color="colors[snp.code]" class="mr-2">mdi-circle</v-icon></span>
          </div>
        </v-row>

        <v-divider></v-divider>

        <v-row class="px-10">
          <p>{{ $t(geneData.geneResult.recomendation) }}</p>
          <div class="px-4">
            <div class="left-border mb-2 pl-2 pt-1">
              <strong class="mr-2">{{ $t("Fenotipo") }}: </strong>
              {{ $t(geneData.geneResult.phenotype) }}
            </div>
            <div class="left-border mb-2 pl-2 pt-1">
              <strong class="mr-2">{{ $t("Actividad") }}: </strong>
              {{ $t(geneData.geneResult.activity) }}
            </div>
          </div>
        </v-row>

        <v-divider></v-divider>

        <v-row class="px-10">
          <p>{{ $t(geneData.summary) }}</p>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-around py-6 mt-8" fluid>
        <v-btn color="darkprimary" rounded depressed @click="emitClose">
          {{ $t("Cerrar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GeneResultDialog",
  components: {},
  props: ["active", "geneData"],
  data() {
    return {
      colors: ["grey", "grey", "green", "yellow", "orange", "red"]
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss" scoped>
.gene-card {
  box-shadow: 0 5px 25px -18px #00000044, 0 -10px 20px inset #00000006;
  width: 100%;
  border: solid 2px #f3f3f3;

  /*Fix para a altura do ultimo item da lista*/
  max-height: 0px;
}
.left-border {
  border-left: solid 6px #4d2cb9;
  display: flex;
}
.v-card .v-card__actions {
  background: #ffffff00;
  box-shadow: 0 5px 30px -20px inset #310ab1;
}

#gene-result-dialog .text-h4 {
  font-size: 0.85rem !important;
}
.lightbulb {
  animation-name: lightbulb;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;

}
@keyframes lightbulb {
  from{filter:brightness(1) saturate(1)}
  to{filter:brightness(1.5) saturate(1.5)}
}
</style>
