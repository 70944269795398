<template>
  <div>
    <a v-if="entity" @click="goToEntity" class="black--text">
      {{
        formatEntityIdAsName(elementId)
      }}
    </a>
    <external-active-link
      v-else-if="externalSite"
      :externalSite="externalSite"
      :elementId="elementId"
      :linkText="elementId"
    />
    <a v-else href="#" >
      {{
        formatEntityIdAsName(elementId)
      }}
    </a>
    <slot></slot>
  </div>
</template>

<script>

import ExternalActiveLink from "@/components/mylogy/ExternalActiveLink.vue";
export default {
  name: "ActiveChip",
  components: { ExternalActiveLink },
  props: {
    searchString: {
      type: String,
      default: "",
    },
    externalSite: {
      type: String,
      required: false,
    },
    entity: {
      type: String,
      required: false,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    elementId: {
      type: String,
      required: true,
    },
  },
  computed: {
    searchQuery: function() {
      return this.searchString.split(" ");
    },
  },
  methods: {
    goToEntity() {
      this.$router.push({
        name: `Explorer.${this.entity}_detail`,
        params: { id: this.elementId },
      });
    },
    formatEntityIdAsName(entityId) {
      if (this.entity == "drug") {
        return this.$t(entityId);
      } else {
        return entityId.replace("/-/gi", " ").toUpperCase();
      }
    },
  },
};
</script>

<style scoped>
.md-chip.md-theme-default {
  background-color: transparent;
  padding-left: 1px;
  padding-right: 0;
  text-transform: capitalize;
  cursor: pointer;
}
</style>
