import Appbase from 'appbase-js';
import { URL } from '@/helpers/constants'

const drugs = new Appbase({
  url: URL,
  app: "drugs",
});


const disseases = new Appbase({
  url: URL,
  app: "icd10",
});

const genes = new Appbase({
  url: URL,
  app: "genes",
});

const panels = new Appbase({
  url: URL,
  app: "panels",
});

export default {drugs, disseases, genes, panels};