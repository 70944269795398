<template>
    <a class="external-active-link" @click="goToEntityExternalLink">
        {{formatLinkText(linkText)}}
    </a>
</template>

<script>


export default {
  name: "ExternalActiveLink",
  props: {
      externalSite: {
          type: String,
          required: true
      },
      elementId: {
          type: String,
          required: true
      },
      linkText: {
          type: String,
          required: true
      }
  },
  data: function() {
    return {
      externalLocationsPrefixes: {
          omim: "https://www.omim.org/entry/",
          essais: "https://www.thermofisher.com/order/genome-database/details/genotyping/",
          pharmGKBGene: "https://www.pharmgkb.org/gene/",
          ghrGene: "https://ghr.nlm.nih.gov/gene/",
          pubChem: "https://pubchem.ncbi.nlm.nih.gov/compound/"

      },
    };
  },
  methods: {
      goToEntityExternalLink() {
           window.open(`${this.externalLocationsPrefixes[this.externalSite]}${this.elementId}`, '_blank');
      },
      formatLinkText(linkText) {
          return linkText.replace('/-/gi', ' ')
      }
  },
};
</script>

<style scoped>
.external-active-link {
    cursor: pointer;
}
</style>