<script>
import { mapGetters } from "vuex";

export default {
  name: "GeneResultMixin",
  props: {
    gene: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      colors: ["grey", "grey", "green", "yellow", "orange", "red"]
    };
  },
  computed: {
    ...mapGetters("pgx", ["getGeneResult"]),
    geneResult() {
      return this.getGeneResult(this.gene._id);
    },
    snpsInvolved() {
      return [...this.getGeneResult(this.gene._id).snps_involved].sort((a, b) =>
        a.code < b.code ? 1 : b.code < a.code ? -1 : 0
      );
    },
    geneNameResult() {
      return `${this.geneResult.gene.toUpperCase()} - (${
        this.geneResult.genotype
      })`;
    },
    geneColor() {
        return this.colors[this.geneResult.code]
    },
    geneSymbol() {
        return this.gene.gene 
    },
    geneSummary() {
        return  this.gene[`summary_${this.$i18n.locale}`] || this.gene[`summary_en`] 
    },
    geneSNPs() {
      return this.gene.snps
    },
    genePharmgkb() {
      return this.gene.pharmgkb
    },
    geneOMIM() {
      return this.gene.omim
    },
    geneLocus() {
      return this.gene.locus
    },
    geneAliases() {
      return this.gene.aliases || []
    },
    geneName() {
        return  this.gene[`name_${this.$i18n.locale}`] || this.gene[`name_en`] 
    },
    geneDiseases() {
        return  this.gene[`diseases_${this.$i18n.locale.toUpperCase()}`] || this.gene[`diseases_EN`] || []
    },
    geneGenotype() {
        return this.geneResult.genotype 
    },
    geneActivity() {
        return this.geneResult.activity 
    },
    genePhenotype() {
        return this.$t(this.geneResult.phenotype)
    },
    //
    geneData() {
      return {
        id: this.gene._id,
        symbol: this.geneSymbol,
        name: this.geneName,
        nameResult: this.geneNameResult,
        genotype: this.geneGenotype,
        phenotype: this.genePhenotype,
        color: this.geneColor,
        geneResult: this.geneResult,
        snpsInvolved: this.snpsInvolved,
        summary: this.geneSummary,
        geneSNPs: this.geneSNPs,
        genePharmgkb: this.genePharmgkb,
        geneOMIM: this.geneOMIM,
        geneLocus: this.geneLocus,
        geneAliases: this.geneAliases,
        geneDiseases: this.geneDiseases
      };
    }
  },
  methods: {}
};
</script>
