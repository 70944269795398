<template>
    <ActiveChip entity="gene" :elementId="geneResults.gene">
      <span v-if="!geneResults.genotype.includes('/')">
          {{geneResults.snp}}
      </span>
      <span>
          {{geneResults.genotype}}
      </span>
      <v-icon :color="colors[geneResults.code]"  class="mr-2">mdi-circle</v-icon>
    </ActiveChip>
</template>

<script>
import ActiveChip from "@/components/mylogy/ActiveChip";
export default {
  name: "GeneResultsDetailed",
  props: ["geneResults"],
  components: { ActiveChip },
  data() {
    return {
      colors: ["grey", "grey", "green", "yellow", "orange", "red"]
    };
  }
};
</script>
